import Moment from 'moment';
import router from 'next/router';
import { IPROTECT_PLANS } from '../../constants';
import {DEVICE_DETAIL_REQUIRED_FIELDS, COPAY_EXEMPTED_COVERS, COPAY_EXEMPTED_PLAN_NAMES_WHILE_RISING_SR} from "../../constants/selfserve";
import moment from 'moment';

// const off_day_list = [0]
const off_day_list = [100]//removing sunday check as its not applicable for all sundays and we get sunday from slot API as well

export const appointmentDateSlot1 = (process='AMC') => {
	const current_time = new Date().getHours()
	// let days = process === 'AMC' ? (current_time >= 8 && current_time < 12) ? 0 : 1 : 1;
	let days = process === 'AMC' && (current_time < 12) ? 0 : 1;
	let tomorrow = Moment(new Date()).add(days, 'days')//adding 0 days to start from today
	if(off_day_list.includes(tomorrow.day())){
		tomorrow = Moment(new Date()).add(2, 'days')
	}
	return tomorrow.format('DD/MM/YYYY')
}

export const appointmentDateSlot2 = (process='AMC') => {
	let day_after = Moment(appointmentDateSlot1(process), "DD/MM/YYYY").add(1, 'days');
	if(off_day_list.includes(day_after.day())){
		day_after = Moment(new Date()).add(2, 'days')
	}
	return day_after.format('DD/MM/YYYY')
}

export const appointmentMaxDateSlot = (days) => {
	let day = Moment(new Date()).add(days, 'days');
	// if(off_day_list.includes(day.day())){
	// 	day = Moment(new Date()).add(days + 1, 'days')
	// }
	return day.format('DD/MM/YYYY')
}

const custDetails = (data:any, whatsapp) => {
	const address1 = (data.customerDetails.address.address || '').toString()
	const address2 = (data.customerDetails.address.address2 || '').toString()
	const appointment_date = data.appointmentProcessType.appointmentDate1 ? (data.appointmentProcessType.appointmentDate1 || '').toString() :  ['Carry-In', '@Home', 'Mail-In', 'Theft'].includes(data.appointmentProcessType.processType) ? moment().add(1, 'days').format('DD/MM/YYYY').toString() : ''
	const appointment_date_1 = appointment_date
	const appointment_time = data.appointmentProcessType.appointmentDate1 ? (data.appointmentProcessType.appointmentTimeSlot1 || '').toString():  ['Carry-In', '@Home', 'Mail-In', 'Theft'].includes(data.appointmentProcessType.processType) ? '10AM - 2PM' : ''	
	const appointment_time_1 = appointment_time
	const city = (data.customerDetails.address.city || '').toString()
	const email = (data.planDetails.order?.email || data.customerDetails.email || '').toString()
	const name = (data.planDetails.order?.name || data.customerDetails.name || '').toString()
	const pincode = (data.customerDetails.address.pincode || '').toString()
	const phone = (data.planDetails.order?.phone || data.customerDetails.phone || '').toString()
	const state = (data.customerDetails.address.state || '').toString()
	const address_id = (data.customerDetails.address.id || '').toString()

		return {
			address1: address1,
			address2: address2,
			appointment_date: appointment_date,
			appointment_time: appointment_time,
			appointment_date_1: appointment_date_1,
			appointment_time_1: appointment_time_1,
			city: city,
			email: email || '',
			name: name,
			pincode: pincode,
			phone: phone || '',
			state: state,
			has_whatsapp_opt_in: whatsapp? whatsapp : null,
			address_id: address_id
		}


}

export const srPayLoad = (data:any, temp_sr:boolean=false, whatsapp) => {
	// Checking if custom Issue is present and adding to Issue List
	const latestSr = data.serviceRequests[0]

	const extraProblem = latestSr.hasOwnProperty('custom_issue') ? latestSr.custom_issue : ''
	const problem = extraProblem || '' === '' ? latestSr.selected_problems : [...latestSr.selected_problems, extraProblem]
	// Ends here
	const selectedCover = latestSr.selected_cover.cover_details[0]
	const token_id = data.token_id
	const plan_id = data.planDetails.id.toString()
	const cover_id = selectedCover.cover_id.toString()
	const request_origin = router.query.source ? `selfserve ${router.query.source}` : data.srCreationSource
	// const problems = latestSr.selected_problems
	const problems = problem
	const customer_details = custDetails(data, whatsapp)
	const isCopayTempSr = temp_sr
	const srReopenReason = latestSr.sr_reopen_reason
	const srReopenReasonComment = latestSr.comment
	const refNo = selectedCover.reopen ? selectedCover.sr_id : null
	const serviceType = selectedCover.type
	const processType = data.appointmentProcessType.processType
	const uploaded_docs:any = data.uploadedDocuments && data.uploadedDocuments.map((item:any) => item.url) || []
	let deviceDetails = data.deviceDetails ? data.deviceDetails : null
	let deviceDetailsFiltered = {}
	if (deviceDetails) {
		if (deviceDetails.device_id) {
			deviceDetailsFiltered['id'] = deviceDetails.device_id
		} else {
			if(deviceDetails.id) {
				deviceDetailsFiltered['id'] = deviceDetails.id
			} else {
				DEVICE_DETAIL_REQUIRED_FIELDS.map(field => {
					if(deviceDetails[field]) {
						deviceDetailsFiltered[field] = deviceDetails[field]
					}
				})
			}
		}
	}
	const formatDocuments = (documents) =>{
		return documents?.map((document) => {
			return {
				url: new URL(document.url).pathname.slice(1),
				name: document.name,
				type: "theft",
				source: "website selfserve"
			}
		}) || []
	}

	return {
		token_id: token_id,
		plan_id: plan_id,
		cover_id: cover_id,
		request_origin: request_origin,
		problems: problems,
		customer_details: customer_details,
		is_copay_temp_sr: isCopayTempSr,
		sr_reopen_reason: srReopenReason,
		sr_reopen_reason_comment: srReopenReasonComment,
		sr_ref_no: refNo,
		service_type: serviceType,
		process_type: processType,
		device_detail: deviceDetails ? deviceDetailsFiltered : {},
		documents: formatDocuments(data.uploadedDocuments),
		comments: data.additional_comments ? data.additional_comments : ''
	}
}

export const getBrandModelCategory = (device_details:{brand:any, model:any, category:any}) => {
    const { brand, model, category } = device_details;
    let brandModelCategory = '';
    if (brand) {
        brandModelCategory += brand;
    }
    if (model) {
        brandModelCategory += ` ${model}`;
    }
    if (category && (brand || model)) {
        brandModelCategory += ` (${category})`;
    } else if (category) {
        brandModelCategory += category;
    }
    return brandModelCategory.trim();
}

function isSlotAvailable(element, _index, _array){
	return element['is_available'] === true
}

export const parseSlotAvailabilityData = (slot_data) => {
	let disabledDates: any = []
	let date_slot_mapping: any = {}
	Object.entries(slot_data).map(data => {
        let key: any = data[0];
        let value: any = data[1];
        if(!value.some(isSlotAvailable)){
            disabledDates.push((Moment(key, 'YYYY-MM-DD')).toDate())
        }
        else{
            date_slot_mapping[key] = []
            value.forEach(slot => {
                if(slot['is_available']){
                    date_slot_mapping[key].push({key: slot['slot_time'], value: slot['slot_time'], text: slot['slot_time']})
                }
            })
        }
    })
	Object.values(date_slot_mapping).forEach((data: any) => {
		data.sort((a, _b) => {
			if (a.key === '2PM - 6PM')
				return 1
			if (a.key === '10AM - 2PM')
				return -1
			return 0
		})
	})
	return {'disabledDates': disabledDates, 'dateSlotMapping': date_slot_mapping, 'less_than_minimum_slots': disabledDates.length >= Math.abs(Object.keys(slot_data).length - 1)}
}


export const slotApiPayload = (selfserveReducer) => {
	let selectedCover = selfserveReducer.serviceRequests[0].selected_cover.cover_details[0]
	let deviceDetail = selfserveReducer.deviceDetails?.category_type ? selfserveReducer.deviceDetails : selfserveReducer.serviceRequests[0].device_detail
	let parent_sr_ref_no = selectedCover.reopen ? JSON.stringify(selectedCover.sr_id) : null
	let brand_list = deviceDetail?.brand ? [deviceDetail.brand] : []
	let d = Object()
	d[selectedCover.cover_title] = 1
	const data = {
		'category': JSON.stringify(deviceDetail.category_type),
		'brand_list': JSON.stringify(brand_list),
		'pincode': JSON.stringify(selfserveReducer.customerDetails.address.pincode),
		'application': 'website',
		'cover_names': JSON.stringify(d),
		'parent_sr_ref_no': parent_sr_ref_no,
		'sub_application': 'selfserve'
	}
	return data
}

export const getActiveIds = (plans) => {
	let ids = plans.filter((plan) => plan.active_sr_exists).map((plan)=> plan.sr_id);
	return ids;
}
export const BLOCKED_CHANNELS = ['tata-aig']

export const appointmentFormat = (date, time) => {
	let slot_date_time_1 = `${Moment(date, 'DD/MM/YYYY').format('DD MMM YYYY')}, ${time}`
	return slot_date_time_1
}

export const generateStaticDates = (remDates = 7) => {
	let date = new Date();
	const timeSlots = [{is_available: true, slot_time: "10 AM - 2 PM"}, {is_available: true, slot_time: "2 PM - 6 PM"}]
	let initialState = {};
	if(date.getHours() < 8){
	  initialState[`${date.getFullYear()}-${(date.getMonth() + 1 % 12)}-${date.getDate()}`] = {timings: timeSlots, isTimeAvailable: true};
	  remDates--;
	} else if(date.getHours() < 12) {
	  initialState[`${date.getFullYear()}-${(date.getMonth() + 1 % 12)}-${date.getDate()}`] = {timings: [{...timeSlots[0], is_available: false}, timeSlots[1]], isTimeAvailable: true};
	  remDates--;
	} else {
		initialState[`${date.getFullYear()}-${(date.getMonth() + 1 % 12)}-${date.getDate()}`] = {timings: [{...timeSlots[0], is_available: false}, {...timeSlots[1], is_available: false}], isTimeAvailable: false};
	}
	return Array(remDates).fill('').reduce((allDates, _currentDate, index) => {
	  allDates[`${Moment().add(index + 1, 'days').format('YYYY-MM-DD')}`] = {isTimeAvailable: true, timings: timeSlots};
	  return allDates
	}, initialState);
}

export const generateDeviceKey = (planDetails, deviceDetails) => {
	let key = '';
	let categoryType = deviceDetails.category_type ? deviceDetails.category_type : planDetails.category_type;
	let category = deviceDetails.category ? deviceDetails.category : planDetails.category;
	let deviceId = deviceDetails.device_id ? deviceDetails.device_id : Math.round(Math.random() * parseInt(planDetails.id));
	key += categoryType ? categoryType.split(' ').reduce((currentKey, i) => (currentKey + i[0].toLowerCase()), key) : '';
	key += category ? category.split(' ').reduce((currentKey, i) => (currentKey + i[0].toLowerCase()), key) : '';
	key += deviceId.toString()

	return key;
}

export const BLOCKED_BRANDS = {
	'gopro': ['Digital Camera']
}

export const isBrandBlocked = (plan) => {
	let brand = plan.brand ? plan.brand?.toLowerCase() : plan.device_detail?.brand?.toLowerCase()
	let category_type = plan.category_type ? plan.category_type : ""
	let model = plan.model ? plan.model : plan.device_detail?.model
	if (!brand || brand === 'others' && model){
		brand = getBrandFromModel(model)	
	}
	if(brand in BLOCKED_BRANDS && [category_type, 'all'].some((ele) => BLOCKED_BRANDS[brand].includes(ele))) {
		return true
	}
	else{
		return false
	}
}

export const getBrandFromModel = (model) => {
	model = model?.split(" ").join("").toLowerCase()
	let brand = "";
	if(model) {
		Object.keys(BLOCKED_BRANDS).forEach(key => {
			if(model.includes(key)){
				brand = key;
			}
		});
	}
	return brand;
}

export const isTempSrRequired = (isCopay, isReopenCover, coverTitle, planName, isCoverCopay) => {
	let tempSR = isCopay;
	if ((isCopay && (isReopenCover || COPAY_EXEMPTED_COVERS.includes(coverTitle) || !isCoverCopay))
	    || COPAY_EXEMPTED_PLAN_NAMES_WHILE_RISING_SR.includes(planName)){
		tempSR = false;
	}
	return tempSR;
}

export const isCopaymentRequired = (isCopay, isReopenCover, coverTitle, planName, isCoverCopay) => {
	return (isCopay && !isReopenCover && !COPAY_EXEMPTED_COVERS.includes(coverTitle) 
			&& !COPAY_EXEMPTED_PLAN_NAMES_WHILE_RISING_SR.includes(planName)
			&& isCoverCopay)
}

export const isIproPlan = (planName) => {
	return IPROTECT_PLANS.includes(planName.toLowerCase())
}

export const addAppRenderParams = (router) => {
	if(router?.query?.app_render){
		router.query.app_render_close = "true"
		router.push(router)
	}
}