import Config from '../config';
import moment from 'moment';
import {UTM_FIELDS, ADDITIONAL_COOKIES, CHANNEL_FOR_AGENT_APPLICATION, QUERY_PARAM, IR_PRODUCT_GROUPS, HC_PRODUCT_GROUPS, AMC_GROUPS, SPILLS_AND_DROPS, EXTENDED_WARRANTY ,regExpEmail, TOTAL_PROTECTION_GROUPS, DISABLED_INPUT_INJECTION_CHARACTERS} from '../constants';
import store from '../redux'
import { hasPermission } from './userProfile';
import { getAgentCookie } from '../api/cookies';
import Cookies from 'js-cookie'
import { getSelectedCityFromCookie } from './cities';
import { FetchLocation, SearchLocation } from '../api/home';

export const scrollTo = (offset, callback) => {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll)
      if(callback){
        callback()
        // Trigger again to handle app promotion banner
        setTimeout(()=>{scrollTo(offset, false)}, 500);
      }
    }
  }

  window.addEventListener('scroll', onScroll)
  onScroll()
  window.scrollTo({
    top: offset,
    behavior: 'smooth'
  })
}

export const routerPush = (router: any, slug: string) => {
  router.push(`${combineParamsString(slug, getUtmString())}`)
};

export const groupBy = (items, key) => {
  // Return the end result
  return items.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const isDeviceType = (device) => {
  let obj = { isAC: false, isWP: false, isCH: false }
  switch(device){
    case "Air Conditioner":
      obj.isAC = true;
      break;
    case "Water Purifier":
      obj.isWP = true;
      break;
    case "Chimney & Hob":
      obj.isCH = true;
      break;
  }
  return obj
}

export const getParamsObjectFromString = (path:string) => {
  path = decodeURIComponent(path)
  let paramsString = ''
  let urlParams = {}
  if(path.split('?').length > 1) {
    paramsString = path.split('?')[1]
    paramsString.split('&').forEach((param) => {
      if(param.split('=').length > 1) {
        urlParams[param.split('=')[0]] = param.split('=')[1]
      }
    })
  }

  return {urlParams,paramsString}
}

export const getNextPath = (path:string) => {
  let params:any = getParamsObjectFromString(path);
  if(params.urlParams?.next){
    return params.urlParams.next;
  }
  return '/'
}

export const getDeviceFromSlug = (slug:string) => {
  let words = slug ? slug.split('-') : [];

  if (words.length > 0) {
    for (let i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    words[words.length - 1] = words[words.length - 1].slice(0, words[words.length - 1].length)
    return words.join(' ');
  } else {
    return ''
  }
}

export const getSingularCategory = (deviceName:string) => {
  let device = deviceName
  if(device[device.length -1] == 's'){
    device = device.substr(0,device.length -1 )
  }
  if (device == 'Smart Watche') {
    device = device.substr(0,device.length -1 )
  }
  return device;
}

export const formatDate = (date:any, format="YYYY-MM-DD", from_format="") => {
  if(!!date){
    let momentDateObj:any;
    if(from_format){
      momentDateObj = moment(date, from_format)
    }else{
      momentDateObj = moment(date)
    }
    if(momentDateObj.isValid()){
      return momentDateObj.format(format)
    }
  }
  return ''
}

export const toDateObject = (date:any, format="YYYY-MM-DD") => {
  if(!!date){
      let val =  moment(date, format)
      if(val.isValid()){
          return val.toDate()
      }
  }
  return undefined
}

export const formatNumber = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value).split('.')[0];

export const getStaticPath = (path) => {
  if(typeof(path) == 'string' && path.indexOf("http")==0){
    return `${path}`;
  }
  return `${Config.ASSETPREFIX_PATH}${path}`;
}


const _calculate = function(originalStr) {
  var delta, i, sum;
  sum = 0;
  delta = [0, 1, 2, 3, 4, -4, -3, -2, -1, 0];
  i = 0;
  while (i < originalStr.length) {
    sum += parseInt(originalStr.substring(i, i + 1));
    i++;
  }
  i = originalStr.length - 1;
  while (i >= 0) {
    sum += delta[parseInt(originalStr.substring(i, i + 1))];
    i -= 2;
  }
  if (10 - (sum % 10) === 10) {
    return 0;
  }
  return 10 - (sum % 10);
};
export const isValidIMEI = function(luhnStr) {
  var luhnStrDigit, luhnStrLess;
  luhnStrDigit = void 0;
  luhnStrLess = void 0;
  luhnStrDigit = parseInt(luhnStr.substring(luhnStr.length - 1, luhnStr.length));
  luhnStrLess = luhnStr.substring(0, luhnStr.length - 1);
  if (luhnStr.length===15 && _calculate(luhnStrLess) === parseInt(luhnStrDigit)) {
    return true;
  }
  return false;
};

export const setUtmCookies = (url) => {
  const params = url.split('?').length > 1 ? url.split('?')[1] : ''
  let expDate = new Date()
  expDate.setDate(expDate.getDate() + 30)
  let cookies:any = document.cookie.split(';')
  let unchangedCookies:any = []
  try{
    if(params !== '') {
      params.split('&').forEach(param => {
        if(param.includes('utm') || ADDITIONAL_COOKIES.includes(param.split('=')[0])) {
          if(cookies.find(c => c.includes(param))) {
            //cookie exists and has same value
            unchangedCookies.push(param)
          } else {
            if(QUERY_PARAM.includes(param.split('=')[0]))
            {
              document.cookie = param
            }
            else
            {
              if(!(param.includes('utm_campaign') && cookies.some(c => c.includes('utm_campaign')))){
                document.cookie = `${param};expires=${expDate.toUTCString()}`
              }
            }
          }
        }
      });
    }
  }
  catch(err) {
    console.log(err)
  }
}

export const getUtmParams = () => {
  let tracking_params = {}
  let cookies = document.cookie.split(';')
  UTM_FIELDS.forEach((field) => {
      let current_cookie = cookies.find(c => c.split('=')[0].includes(field))
      if(current_cookie &&  current_cookie.split('=').length > 1) {
        tracking_params[field] = current_cookie.split('=')[1]
      }
    })
   return tracking_params
}

export const getUtmString = () => {
  let utm_string = ''
    if(typeof window!= 'undefined'){
      let cookies = document.cookie.split(';')
    UTM_FIELDS.forEach((field) => {
      let current_cookie = cookies.find(c => c.split('=')[0].includes(field))
      if(current_cookie && current_cookie.split('=').length > 1) {
        if(utm_string !== '') {
          utm_string += '&'
        }
        utm_string += `${field}=${current_cookie.split('=')[1]}`
      }
    })
    }
  return utm_string
}

export const combineParamsString = (url:string, newParams:string) => {
  let combinedUrl = url
  if(newParams == '' || newParams == null || newParams == undefined) {
    return url
  }
  if (!combinedUrl.includes('?')) {
    combinedUrl += '?'
  }
  if(newParams.includes('?')) {
    newParams = newParams.replace('?', '')
  }
  if(newParams.lastIndexOf('&') == newParams.length - 1) {
    newParams = newParams.slice(0, newParams.length - 1)
  }

  let splitParams = newParams.split('&')
  let filteredParams = ''

  splitParams.forEach((p) => {
    if(combinedUrl.indexOf(p) < 0) {
      filteredParams += filteredParams == '' ? p : `&${p}`
    }
  })

  if(combinedUrl.indexOf('?') == combinedUrl.length - 1) {
    if(filteredParams.indexOf('&') == 0) {
      combinedUrl = combinedUrl + filteredParams.replace('&', '')
    } else {
      combinedUrl = combinedUrl + filteredParams
    }
  } else {
    if(combinedUrl.lastIndexOf('&') !== -1 && combinedUrl.lastIndexOf('&') == combinedUrl.length - 1) {
      if(filteredParams.indexOf('&') == 0) {
        combinedUrl = combinedUrl + filteredParams.replace('&', '')
      } else {
        combinedUrl = combinedUrl + filteredParams
      }
    } else {
      if(filteredParams.indexOf('&') == 0) {
        combinedUrl = combinedUrl + filteredParams
      } else {
        combinedUrl = combinedUrl + '&' + filteredParams
      }
    }
  }
  return combinedUrl
}

export const generateTabId = () => {
  let tabId = window.sessionStorage['tabId']
  if (tabId) {
    return tabId;
  }
  else {
    tabId = Date.now().toString()
    window.sessionStorage['tabId'] = tabId
    return tabId;
  }
}

export const getApplication = () => {
  const state = store.getState()
  const isCustomerEligible = CHANNEL_FOR_AGENT_APPLICATION.includes(state.cart.channel)
  if (isAgent() || isCustomerEligible) {
        return 'agent'
      }
  else {
    return 'website'
  }
}

export const isAgent = () => {
  const agentProfile = getAgentCookie()
  return CHANNEL_FOR_AGENT_APPLICATION.includes(store.getState().cart.channel) || (
    agentProfile.username && hasPermission('website|web_pages|link_generation', agentProfile.permissions)
  )
}

export const getChannelName = () => {
  return isAgent() ? 'website-tele' : 'website'
}

export const getCategory = (category) => {
  if(category.includes('Chimney')){
    category = 'Chimney'
  }else if(category.includes('AC')){
    category = 'Air Conditioner'
  }else if(category.includes('Water Purifier')){
      category = 'Water Purifier'
  }
  return category;
}

export const getSkuSelectionInfo = (props) => {
  const MOBILE_TABLET_CATEGORIES = ['iPhone', 'iPad', 'Android Phone', 'Android Tablet']
  const MOBILE_TABLET_CATEGORY_TABS = ['Android', 'Apple']

  let response = {}
  let categories = props.categories;
  let is_mobile_tablet = true;
  categories.forEach(category => {
    if (!MOBILE_TABLET_CATEGORIES.includes(category)) {
      is_mobile_tablet = false;
    }
  });

  if (is_mobile_tablet) {
    response['is_mobile_tablet'] = is_mobile_tablet;
    response['mobile_tablet_category_tabs'] = MOBILE_TABLET_CATEGORY_TABS
    response['category_mapping'] = {
      'Android': ['Android Phone', 'Android Tablet'],
      'Apple': ['iPhone', 'iPad']
    }
  }

  //add any other information needed here

  return response
}

export const arrangeCategories = (categories, category_type) => {
  if (category_type === "Laptop") {
    return categories.reverse();
  }
  return categories;
}

export const capitalize = (str, _lower = false) =>
str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

export const getMethodName = (value) => {
  let regSr = /^\d{6}-\d{6}/
  if(regExpEmail.test(value)){
    return 'email'
  }else if(regSr.test(value)){
    return 'sr no';
  }else{
    return 'mobile'
  }
}

export const detectBrowser = () => {
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        return 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (document.hasOwnProperty("documentMode"))) {
        return 'IE';//crap
    } else {
        return 'Unknown';
    }
}
export const isEWPlan = (slug) =>{
  return slug && (slug.includes("extended-warranty") || slug.includes("protection-plans") || slug.includes("protection-plan")) 
}

export const filterQuestionsForCampaign = (questions) => {
  let filtered_questions = questions.filter(question => !question['title'].includes("Comprehensive"));
  return filtered_questions
}

// Sort Items in Grid based on city
export const sortItems = (data:any) => {
  let cookie_city = getSelectedCityFromCookie() || 'Mumbai'
  const sorted_items = data.items.map((a)=>{
     if(a.position.hasOwnProperty(cookie_city)){
      a.new_position = a.position[cookie_city]
     }else{
      a.new_position = a.position['default']
     }
     return a
  })
  sorted_items.sort((a,b)=> a.new_position - b.new_position)
  return sorted_items;
}

// Enable or Disable IR / AMC Sku
export const isIrAmcAddButtonEnabled = (category_type,config_data) => {
  const cookie_city = getSelectedCityFromCookie()
  const campaigns_cookie = Cookies.get('special_campaign')
  if(config_data && config_data.status){
    // check category
    if(config_data.category_types[category_type]){
      const category_config = config_data.category_types[category_type];

      // allowed campaigns
      if(campaigns_cookie && category_config.allowed_campaigns.length){
          const is_allowed_campaign = category_config.allowed_campaigns.some((item)=>{
            return campaigns_cookie.includes(item);
          })
          if(is_allowed_campaign){
            return true
          }
      }
      // allowed cities 
      if(cookie_city && category_config.blocked_cities.map((city)=>city.toLowerCase()).includes(cookie_city.toLowerCase())){
        return false
      }
    }

  }
  return true
}

// Enable or Disable IR / AMC Sku
export const paylaterEnable = (items,config_data) => {
  if(!config_data.status){
    return true
  }
  const isIrDisable = items.find((item,_index)=>{
    if(item.group=='insta-repair'){
      let category_type = item.category_type;
      return config_data.category_types[category_type] && !config_data.category_types[category_type].allow_postpaid
    }
  })
  return !isIrDisable;  
}

export const haversineDistance = (lat1, long1, lat2, long2) => {
  let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
  let dLon = ((long2 - long1) * Math.PI) / 180.0;
  lat1 = (lat1 * Math.PI) / 180.0;
  lat2 = (lat2 * Math.PI) / 180.0;
  let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  let rad = 6371;
  let c = 2 * Math.asin(Math.sqrt(a));
  return rad * c * 1000;
};

export const getFilteredPredictionList = async (list:any = [], city) => {
  if (list.length == 0 || list.info === "no result found" || list.info === "failed")return []
  let predictionList:any = [];
  let fetchPromises:any = [];
  list.forEach((item) => {
    let fetchPromise = FetchLocation({ place_id: item.place_id })
      .then((res) => {
        if (res?.data?.city?.toLowerCase() === city?.toLowerCase()) {
          predictionList.push(item);
        }
      });

    fetchPromises.push(fetchPromise);
  });

  // Wait for all API calls to finish
  await Promise.all(fetchPromises);
  return predictionList;
};

export const handleSelectedPrediction = async (prediction, pincode, city, state) => {
  let data:any = {}
  await SearchLocation({ text: prediction.description, type: 'geocode', source:"Verify Address on SR Creation" })
  .then(res=>{
      let lat = res?.data?.results[0].geometry.location.lat;
      let lng = res?.data?.results[0].geometry.location.lng;
      let add_comp = res?.data?.results[0].address_components;
      let pincodecomp = (add_comp || []).filter(comp => comp.types.includes('postal_code'));
      pincode = pincodecomp.length ? pincodecomp[0].long_name : pincode
      data = {latLng: {lat: Number(lat), lng: Number(lng)}, area: prediction.description.split(",")[0], formatted_address: prediction.description, pincode: pincode, city: city, state: state}
  })
  .catch(e => {
    console.log(e)
    return {}
  })
  return data
}

export const buildURL = (baseURL, parameters) => {
  const queryString = Object.keys(parameters)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`)
    .join('&');
  return `${baseURL}?${queryString}`;
};

export const truncatedString = (input, size) => {
  return input?.length > size ? input.substring(0, size)+"..." : input
}
export const dateToFormat = (value) => {
  let date = value.split('-')
  return [date[0].padStart(4, '0'), date[1].padStart(2, '0'), date[2].padStart(2, '0')].join('-');
}

export const getSkuTitle = (item) => {
  if ([ ...AMC_GROUPS].includes(item.group)) {
    return formatProductNameForAmc(item.category_type,item.display_title,item.is_renewals,item.additional_details.brand,item.category)
  } 
  if ([...IR_PRODUCT_GROUPS].includes(item.group)) {
    return `${item.brand ? item.brand : ""} ${item.category} - InstaRepair`
  }   
   if ([...HC_PRODUCT_GROUPS].includes(item.group)) {
    return `${item.product_title} ${item.is_renewals?"(Renewals)":""}`
  }
  if ([...SPILLS_AND_DROPS,...EXTENDED_WARRANTY].includes(item.group)) {
    return `${item.category} ${item.display_title} ${item.is_renewals?" (Renewals)":""}`
  }
  if ([...TOTAL_PROTECTION_GROUPS].includes(item.group)) {
    return `${item.display_title} ${item.is_renewals?" (Renewals)":""}`
  }
    return `${item.category} ${item.display_title} ${item.is_renewals?" (Renewals)":""}`
};

export const formatProductNameForAmc = (category_type:string, product_name:string, is_renewals:boolean, brand:string,category?:string) => {
  let productName = product_name;
  let categoryType = category_type;
  if(productName=='Annual Maintenance Contract'){
    productName= 'Basic AMC'
  }
  if(categoryType=='Air Conditioner'){
    categoryType = 'AC'
  }
  if(categoryType=='Cookware'){
    categoryType = 'Hob'
  }
  if(productName == "Laptop Basic Care"){
    return `${productName} for ${category?category:categoryType}`
  }
  if (is_renewals){
    return brand ? `${brand} - ${productName} for ${categoryType}` : `${productName} for ${categoryType} (Renewals)`
  }
  return brand ? `${brand} - ${productName} for ${categoryType}` : `${productName} for ${categoryType}`
}

export const getTestimonialImage = (item, is_mobile) => {
  if(item.customer_mobile_image && is_mobile){
    return item.customer_mobile_image  
  }else if(item.customer_image){
    return item.customer_image
  }
  return getStaticPath('/static/images/testimonials/testimonial-placeholder.png')
}

export const syncClarityCleverTapID = (clevertap:any) => {
  let retry = 0
  const syncClevertapID = () => {
    try {
      if(window && clevertap.getCleverTapID()) {
        window.clarity("set", "userId", clevertap.getCleverTapID().toString())
        clearInterval(intervalID)
      }
    }
    catch(e) {
      console.log("Unable to sync clarity clavertap ID. trying again..", e)
    }
    if(retry > 3) {
      clearInterval(intervalID)
      console.log("Unable to sync clarity clavertap ID")
    }
    retry++
  }
  const intervalID = setInterval(syncClevertapID, 5000) 
}

export const validateInput = (e:any) =>{
  return { 
    target: { 
      name: e.target.name, 
      value: DISABLED_INPUT_INJECTION_CHARACTERS.includes(e.nativeEvent.data) ? e.target.value.slice(0,-1) : e.target.value 
    } 
  } 
}

export const getValidPath = (path: string) => {
  return path.startsWith("/") ? path : "/"
}

export const secondsToHHMM = seconds => {
  const duration = moment.duration(seconds, 'seconds');
  if (seconds >= 180) {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    // Format the result as HH:MM
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} hrs`;
  } else {
    const minutes = Math.floor(duration.asMinutes());
    const remainingSeconds = Math.floor(duration.asSeconds()) % 60;
    // Format the result as MM:SS
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')} mins`;
  }
}

export const getTruncatedString = (value, size) => {
  return value.length > size ? value.substring(0, size - 1) + "..." : value
}

export const isConsumerChannel = (channel:string) => {
  const consumer_channels = ['website','consumerapp','website-tele','onsitego-scouts']
  return consumer_channels.includes(channel)
}

export const getUrlParams = (url, router) => {
  return combineParamsString(url, getParamsObjectFromString(router.asPath).paramsString)
}


export const extractMobilenumberAndText = input => {
  const phoneNumberPattern = /(\+\d{1,3}[- ]?)?\d{1,4}(?:[- ]?\d{1,4}){1,4}/g;
  const match = input.match(phoneNumberPattern);

  if (match && match.length > 0) {
    const phoneNumber = match[0];
    const phoneIndex = input.indexOf(phoneNumber);
    const part1 = input.substring(0, phoneIndex).trim();
    const part3 = input.substring(phoneIndex + phoneNumber.length).trim();
    return {
      beginning: part1,
      number: phoneNumber,
      ending: part3,
    };
  }
  return {
    beginning: input,
    number: '',
    ending: '',
  };
};
export const isPromise = (value: Promise<any>) => {
  return value && typeof value.then === 'function';
}
